

























































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { ValidationObserver } from 'vee-validate';
import { CurrentManager } from '@/models/manager/CurrentManager';
import { State } from '@/models/State';
import { DataContainerStatus } from '@/models/Common';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import FormTextArea from '@/components/common/form-elements/FormTextArea.vue';

@Component({
  components: {
    FormInput,
    FormTextArea,
    ValidationObserver,
  },
})
export default class TabGeneral extends Vue {
  @Getter getCurrentManager!: CurrentManager;
  @Action submitGeneralSettings!: Function;
  @Action(addToastMessage) addToastMessage!: Function;
  @Getter getManagerProfileData!: { [key: string]: string } | null;
  @StateClass manager!: State['manager'];
  @StateClass generalSettings!: State['generalSettings'];

  @Ref('form') readonly form!: HTMLFormElement;

  @Watch('generalSettings.status')
  onGeneralSettingsChanged(newState: DataContainerStatus, oldState: DataContainerStatus): void {
    if (newState && newState === DataContainerStatus.Success) {
      this.form.reset();
      this.addToastMessage({
        text: 'Your settings have been saved.',
        type: 'success',
      });
    }
  }

  /**
   * Name getter.
   */
  get name(): string {
    return this.getProfileField('name');
  }

  /**
   * Name setter. Directly commit to state.
   */
  set name(name: string) {
    this.$store.commit('updateProfileName', name.trim());
  }

  /**
   * E-mail getter.
   */
  get email(): string {
    return this.getCurrentManager.email || '';
  }

  /**
   * Phone getter.
   */
  get telephone(): string {
    return this.getProfileField('telephone');
  }

  /**
   * Telephone setter. Directly commit to state.
   */
  set telephone(telephone: string) {
    this.$store.commit('updateProfileTelephone', telephone.trim());
  }

  /**
   * Skype getter.
   */
  get skype(): string {
    return this.getProfileField('skype');
  }

  /**
   * Skype setter. Directly commit to state.
   */
  set skype(skype: string) {
    this.$store.commit('updateProfileSkype', skype.trim());
  }

  /**
   * Slack getter.
   */
  get slack(): string {
    return this.getProfileField('slack');
  }

  /**
   * Slack setter. Directly commit to state.
   */
  set slack(slack: string) {
    this.$store.commit('updateProfileSlack', slack.trim());
  }

  /**
   * Team getter.
   */
  get team(): string {
    return this.getProfileField('team');
  }

  /**
   * Team setter. Directly commit to state.
   */
  set team(team: string) {
    this.$store.commit('updateProfileTeam', team.trim());
  }

  /**
   * Biography getter.
   */
  get biography(): string {
    return this.getProfileField('biography');
  }

  /**
   * Biography setter. Directly commit to state.
   */
  set biography(biography: string) {
    this.$store.commit('updateProfileBiography', biography.trim());
  }

  /**
   * Returns whether we're loading data.
   */
  get loading(): boolean {
    return this.manager === null;
  }

  /**
   * Get profile field by name from store.
   */
  getProfileField(fieldName: string): string {
    if (this.getManagerProfileData) {
      return this.getManagerProfileData[fieldName];
    }

    return '';
  }
}
